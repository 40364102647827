<template>
  <div>
    <b-card
      id="pdfGenerateDiv"
      ref="pdfGenerateDivRef"
      no-body
      class="invoice-preview-card p-1 main-table"
    >
      <div>
        <div
          class="card__inner"
        >
          <table style="width: 100%; page-break-after: auto; border-collapse:separate; border-spacing: 10px 5px;">
            <thead>
              <tr>
                <td>
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">

                    <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                      <div>
                        <div class="logo-wrapper">
                          <img
                            style="width:130px;"
                            src="/nirvana-memorial-garden-logo.png"
                            alt="Logo"
                          >
                        </div>
                      </div>
                      <div class="mt-md-0 mt-2 text-right">
                        <h4
                          class="invoice-title"
                          style="font-size: 12px;line-height: 21px;color: #104D9D; margin:0; font-weight: bold"
                        >
                          {{ ncf.stringID }}
                        </h4>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span
                              class="semi-bold"
                              style="font-weight: bold;"
                            >Action Taken By</span> {{ ncf.createdBy ? ncf.createdBy.name : '' }}, {{ ncf.createdBy ? (ncf.createdBy.department.length ? ncf.createdBy.department[0].name : '') : '' }}
                          </p>
                        </div>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span
                              class="semi-bold"
                              style="font-weight: bold;"
                            >Created On:</span> {{ dateFormatWithTime(ncf.createdAt) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr
                    class="invoice-spacing"
                    style="margin-top:25px!important"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>

                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding pt-0 main__body"
                  >
                    <b-row class="invoice-spacing">

                      <!-- Col: Invoice To -->
                      <b-col
                        cols="12"
                        xl="12"
                        class="p-0"
                      >
                        <h4 style="font-size:14px; text-transform: uppercase; color: #000000; font-weight: 900; margin:0 0 20px">
                          Non-conforming material disposal & Corrective action
                        </h4>
                        <h6
                          class="mb-2"
                          style="font-size:14px; line-height:21px; color:#000000; margin:0 0 10px;"
                        >
                          Non-Conformance Details
                        </h6>
                        <b-table-simple
                          borderless
                          responsive
                        >
                          <b-tbody>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Type of Non-Conformance
                              </b-th>
                              <b-td>{{ ncf.type }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Material
                              </b-th>
                              <b-td>{{ ncf.itemName }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Vendor
                              </b-th>
                              <b-td>{{ ncf.vendor ? ncf.vendor.companyName: '' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Quantity
                              </b-th>
                              <b-td>{{ ncf.quantity ? ncf.quantity : '-' }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                style="font-weight: bold;"
                              >
                                Nature of Defect
                              </b-th>
                              <b-td>{{ ncf.natureOfDefect ? ncf.natureOfDefect : '-' }}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <hr
                    class="invoice-spacing"
                  >
                  <b-card-body
                    class="invoice-padding pt-0 main__body"
                  >
                    <b-row class="invoice-spacing">

                      <!-- Col: Invoice To -->
                      <b-col
                        cols="12"
                        xl="12"
                        class="p-0"
                      >
                        <h6
                          class="mb-2"
                          style="font-size:14px; line-height:17px; font-weight: bold; color:#000000; margin:0 0 10px"
                        >
                          Proposed Solution
                        </h6>
                        <p
                          class="text-bold"
                          style="font-size:11px; font-weight: bold; line-height:16px; margin:0;"
                        >
                          Proposed Action
                        </p>
                        <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ ncf.proposedSolution }}</span>
                        <p
                          class="text-bold"
                          style="font-size:11px; font-weight: bold; line-height:16px; margin:0;"
                        >
                          Remark
                        </p>
                        <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 10px; display: block;">{{ ncf.remarks ? ncf.remarks : '-' }}</span>
                        <p
                          class="text-bold"
                          style="font-size:11px; font-weight: bold; line-height:16px; margin:0;"
                        >
                          Corrective Action By External Provider
                        </p>
                        <span style="font-size:11px; font-weight:400; line-height:16px; margin:0 0 0px; display: block;">{{ ncf.correctiveAction ? ncf.correctiveAction : '-' }}</span>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
              <tr
                style="
            width: 100%; page-break-before: auto;
            page-break-after: auto;
            page-break-inside: avoid;
          "
                class="footer-class-a"
              >
                <td>
                  <div>
                    <div :style="{ height: gapHeight+'mm' }" />
                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0 footer__area">
                      <b-row>

                        <!-- Col: Sales Persion -->
                        <b-col
                          v-for="(signatory, index) of ncf.signatories"
                          :key="index"
                          cols="3"
                          md="3"
                          class="mt-md-1 mt-1"
                          order="2"
                          order-md="1"
                        >
                          <b-card-text class="mb-0">
                            <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                            <div class="sign-box">
                              <div v-if="signatory.signed == true">
                                <div v-if="signatory.user && signatory.user.signature">
                                  <b-img
                                    class="mb-1 mb-sm-0 user__avatar"
                                    height="40"
                                    crossorigin="anonymous"
                                    style="height:40px; width: 90%; object-fit:contain;"
                                    :src="signatory.user.signature"
                                  />
                                </div>
                                <div v-else-if="signatory.user && signatory.user.name">
                                  <span>{{ signatory.user.name }}</span>
                                </div>
                              </div>
                              <div v-else>
                                <p>Pending Signature</p>
                              </div>
                            </div>
                            <p
                              class="ml-75 semi-bold"
                              style="font-weight: bold;"
                            >
                              {{ signatory.user ? signatory.user.name : '' }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                            </p>
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BCardText, BTableSimple, BTbody,
  BTr, BTh, BTd, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCardBody,
  },
  data() {
    return {
      ncf: {},
      gapHeight: 1,
    }
  },
  mounted() {
    this.$http.get(`download/ncf/${this.$route.params.id}/show`)
      .then(response => {
        this.ncf = response.data.data || {}
      })
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

  <style lang="scss">
    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
  </style>

  <style>
    @media print {
        html, body {
            height: 99%;
        }
    }
  </style>
